import { Component } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import Utils from '../../../library/utils';
import connect from 'react-redux/es/connect/connect';
import { addNewUtmString, linkUserWithEmailClick, setInfoFromJukebox } from './AnalyticsActions';
import { window } from 'browser-monads';

const qs = require('query-string');

class AnalyticsContainer extends Component {
  componentDidMount() {
    const utm_source = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).utm_source;
    const utm_medium = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).utm_medium;
    const utm_campaign = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).utm_campaign;
    const utm_content = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).utm_content;
    const email = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).userEmail;
    const timestamp = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).sentEmailTimestamp;

    // Jukebox Media
    const jbFirstName = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).jbFirstName;
    const jbLastName = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).jbLastName;
    const jbEmail = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).jbEmail;
    const jbPhone = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).jbPhone;
    const jbPickup = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).jbPickup;
    const jbDropoff = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).jbDropoff;
    this.props.setInfoFromJukebox(jbFirstName, jbLastName, jbEmail, jbPhone, jbPickup, jbDropoff);
    // Jukebox Media

    var utmString = this.props.location.search;
    while (utmString.charAt(0) === '?') {
      utmString = utmString.substr(1); // remove any '?' at the beginning of string
    }

    if (!Utils.isBlank(utmString)) {
      this.props.saveUtm(utmString);

      if (utm_medium === 'email') { // Track email clicks
        ReactGA.event({
          category: 'Email link clicked',
          action: utm_campaign + ': Clicked on ' + utm_source, // utm_source will be filename of email
          label: utm_content // Button clicked
        });
        ReactPixel.track('Lead');
        if (email && utmString && timestamp) {
          this.props.linkUserWithEmailClick(email, utmString, timestamp);
        }
      }

      if (typeof window !== 'undefined') {
        window.history.replaceState(null, null, window.location.pathname);
      }
    }
  }

  render() {
    return null
  }
}


function mapDispatchToProps(dispatch) {
  return {
    saveUtm: (utmString) => dispatch(addNewUtmString(utmString)),
    linkUserWithEmailClick: (email, utmString, timestamp) => dispatch(linkUserWithEmailClick(email, utmString, timestamp)),
    setInfoFromJukebox: (jbFirstName, jbLastName, jbEmail, jbPhone, jbPickup, jbDropoff) => dispatch(setInfoFromJukebox(jbFirstName, jbLastName, jbEmail, jbPhone, jbPickup, jbDropoff))
  }
}

export default connect(null, mapDispatchToProps)(AnalyticsContainer);
