import { Component } from 'react';
import { LINKS } from '../library/links';
import { navigate } from 'gatsby'
import AnalyticsContainer from '../containers/bootstrap/Analytics/AnalyticsContainer';
import React from 'react';
import Utils from '../library/utils';

const qs = require('query-string');

class RedirectPageContainer extends Component {
  componentDidMount() {
    const to = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).to;

    if (to === 'appstore') { // App Store
      window.open(Utils.getDownloadLink(), '_self');
    } else if (to === 'googleplay') { // Google Play
      window.open(Utils.getDownloadLink('android'), '_self');
    } else if (to === 'techblog') { // Medium Tech blog
      window.open(LINKS.MediumTechBlog, '_self');
    } else if (to === 'kiffgoblog') { // Kiffgo blog
      navigate('/blog')
    } else if (to === 'book') { // Web booking
      navigate('/book')
    } else { // Homepage
      navigate('/')
    }
  }

  render() {
    return (
      <AnalyticsContainer location={this.props.location}/>
    )
  }
}

export default RedirectPageContainer;
